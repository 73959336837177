import React, { useState, useEffect } from "react";
import { getToken } from "../firebaseInit";

const Notifications = (props) => {
  const Token = window.localStorage.getItem("Token");
  const Id = window.localStorage.getItem("ID");
  const Level = window.localStorage.getItem("Level");
  const Department = window.localStorage.getItem("Department");
  const Position = window.localStorage.getItem("Position");
  const Device = "Web";
  const [isTokenFound, setTokenFound] = useState(false);

  console.log("Token found", isTokenFound);
  /// PUT Approve
  async function puttoken(tokendata) {
    return fetch("https://ehr.sahakol.com/node/express/api/notification/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: Token,
      },
      body: JSON.stringify(tokendata),
    })
      .then((data) => data.json())
      .catch((err) => console.log(err));
  }

  // To load once
  useEffect(() => {
    let Token;

    async function tokenFunc() {
      Token = await getToken(setTokenFound);
      if (Token) {
        const respone = await puttoken({
          Id,
          Department,
          Position,
          Level,
          Token,
          Device,
        });
        console.log("Token is", Token);
      }
      return Token;
    }
    tokenFunc();
  }, [setTokenFound]);

  return <></>;
};

Notifications.propTypes = {};

export default Notifications;
