import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./user-pages/Login"));
const Register1 = lazy(() => import("./user-pages/Register"));
const Lockscreen = lazy(() => import("./user-pages/Lockscreen"));

const BlankPage = lazy(() => import("./general-pages/BlankPage"));

// Register Page
const registerPage = lazy(() => import("./recruitment-pages/register"));

// Request Manpower Page
const requestPage = lazy(() => import("./recruitment-pages/requestmanpower"));

// Manpower Page
const manpowerPagemain = lazy(() => import("./recruitment-pages/manpowermain"));
const manpowerPagedept = lazy(() => import("./recruitment-pages/manpowerdept"));
const manpowerPagedeptdetail = lazy(() => import("./recruitment-pages/manpowerdeptdetail"));

// Candidate Page
const candidatePagemain = lazy(() => import("./recruitment-pages/candidatemain"));
const candidatePagedept = lazy(() => import("./recruitment-pages/candidatedept"));
const candidatePagedeptdetail = lazy(() => import("./recruitment-pages/candidatedeptdetail"));
//const candidateInterview1Page = lazy(() => import("./recruitment-pages/candidatedeptdetail"));
//const candidateInterview2Page = lazy(() => import("./recruitment-pages/candidatedeptdetail"));
//const candidatePassPage = lazy(() => import("./recruitment-pages/candidatedeptdetail"));

// PD Approve Page
const pdPage = lazy(() => import("./recruitment-pages/pdapprove"));
const pdReqCheck = lazy(() => import("./recruitment-pages/pdRequestCheck"));
const pdApproveList = lazy(() => import("./recruitment-pages/pdApproveList"));

// Employee Resign Page
const employeeresignPage = lazy(() => import("./recruitment-pages/employeeResign"));

// New Employee Page
const newemployeePage = lazy(() => import("./recruitment-pages/newemployee"));

// Employee Data Page
const employeeDataPage = lazy(() => import("./recruitment-pages/employeeData"));

// Turn Over Rate Page
const turnoverratePage = lazy(() => import("./recruitment-pages/turnoverRate"));
const turnoverrateSearchPage = lazy(() => import("./recruitment-pages/turnoverRateSearch"));

// management Page
const managementMainPage = lazy(() => import("./recruitment-pages/management_main"));
const managementPage = lazy(() => import("./recruitment-pages/management"));

// Profile Page
const progilePage = lazy(() => import("./user-pages/Profile"));
const changePasswordPage = lazy(() => import("./user-pages/ChangePassword"));

const updateActualPage = lazy(() => import("./charts/UpdateActualManpower"));

const AppRoutes = () => {
  const Token = window.localStorage.getItem("Token");
  if (!Token) {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/user-pages/login-1" component={Login} />
          <Redirect to="/user-pages/login-1" />
        </Switch>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path="/dashboard" component={Dashboard} />

        <Route path="/basic-ui/buttons" component={Buttons} />
        <Route path="/basic-ui/dropdowns" component={Dropdowns} />
        <Route path="/basic-ui/typography" component={Typography} />

        <Route path="/form-Elements/basic-elements" component={BasicElements} />

        <Route path="/tables/basic-table" component={BasicTable} />

        <Route path="/icons/mdi" component={Mdi} />

        <Route path="/charts/chart-js" component={ChartJs} />

        <Route path="/user-pages/login-1" component={Login} />
        <Route path="/user-pages/register-1" component={Register1} />
        <Route path="/user-pages/lockscreen" component={Lockscreen} />

        <Route path="/error-pages/error-404" component={Error404} />
        <Route path="/error-pages/error-500" component={Error500} />

        <Route path="/general-pages/blank-page" component={BlankPage} />

        <Route path="/recruitment/register" component={registerPage} />

        <Route path="/recruitment/requestmanpower" component={requestPage} />

        <Route path="/recruitment/manpower" component={manpowerPagemain} />
        <Route path="/recruitment/manpowerdept/:Department" component={manpowerPagedept} />
        <Route path="/recruitment/manpowerdeptdetail/:Department/:Position" component={manpowerPagedeptdetail} />

        <Route path="/recruitment/candidate" component={candidatePagemain} />
        <Route path="/recruitment/candidatedept/:Department" component={candidatePagedept} />
        <Route path="/recruitment/candidatedeptdetail/:Department/:Position/:Details" component={candidatePagedeptdetail} />

        <Route path="/recruitment/pdapprove" component={pdPage} />
        <Route path="/recruitment/pdrequestcheck" component={pdReqCheck} />
        <Route path="/recruitment/pdapprovelist" component={pdApproveList} />
        
        <Route path="/recruitment/managementmain" component={managementMainPage} />
        <Route path="/recruitment/management" component={managementPage} />

        <Route path="/recruitment/employeeresign" component={employeeresignPage} />
        <Route path="/recruitment/newemployee" component={newemployeePage} />
        {/*<Route path="/recruitment/employeedata" component={employeeDataPage} />*/}
        <Route path="/recruitment/employeedata/:Departmentparam/:Positionparam/:Detailparam" component={employeeDataPage} />

        <Route path="/recruitment/tor" component={turnoverratePage} />
        <Route path="/recruitment/torsearch/:Start/:End" component={turnoverrateSearchPage} />

        <Route path="/update/actual/manpower" component={updateActualPage} />

        <Route path="/profile" component={progilePage} />
        <Route path="/changepassword" component={changePasswordPage} />

        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
