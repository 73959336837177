import firebase from "firebase/app";
import "firebase/messaging";
import {
  isBrowser
} from "react-device-detect";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCiw85Pq5R_OOaZYnKZoisLHf2nhejARRg",
  authDomain: "sahakol-ehr.firebaseapp.com",
  projectId: "sahakol-ehr",
  storageBucket: "sahakol-ehr.appspot.com",
  messagingSenderId: "821054844405",
  appId: "1:821054844405:web:d5bc49631d51865a1684bc",
  measurementId: "G-3QP6KDQXWC",
};

firebase.initializeApp(firebaseConfig);

export const getToken = async (setTokenFound) => {
  let currentToken = "";
  if (isBrowser) {
    const messaging = firebase.messaging();

    const publicKey = 'BJBxLcTSoKaQjhTH7429OP39b_4VoSSLSU8s2nNp4I3EZ3X6ILdf6_KNViznF7WqgQCZ08FCHSpayY9cL-Sz7Ck';

    try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
  }else{
    setTokenFound(false);
  }

  return currentToken;
};

export const onMessageListener = () =>{
  if(isBrowser){
    const messaging = firebase.messaging();

    new Promise((resolve) => {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    });
  }
}
  
