import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import Swal from "sweetalert2";

const Token = window.localStorage.getItem("Token");

const logoutState = () => {
  localStorage.clear();
  window.location.reload(false);
};

class Sidebar extends Component {
  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      ReqApprove: [],
      DataisLoaded: false,
    };
  }

  async componentDidMounts() {
    try {
      const res = await fetch(
        "https://ehr.sahakol.com/node/express/api/pd/getpd/request/waitting/total",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: Token,
          },
        }
      );
      this.setState({ ReqApprove: await res.json() });
    } catch (e) {
      console.error(e);
    }
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/recruitment", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    //console.log(Level);
    const Level = window.localStorage.getItem("Level");
    const DataName = window.localStorage.getItem("Name");
    const phofile = window.localStorage.getItem("Avatar");
    const DataDept = window.localStorage.getItem("Department");
    const DataPosition = window.localStorage.getItem("Position");
    //const { ReqApprove, DataisLoaded } = this.state;
    //const ID = window.localStorage.getItem("ID");
    //const Avatars = "https://ehr.sahakol.com/node/express/api/view/avatar/" + ID;
    const Avatar = phofile;
    //alert(DataisLoaded);
    //src={require("../../assets/images/faces/face1.jpg")}
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <a
              href="!#"
              className="nav-link"
              onClick={(evt) => evt.preventDefault()}
            >
              <div className="nav-profile-image">
                <img src={Avatar} alt="profile" />
                <span className="login-status online"></span>{" "}
                {/* change to offline or busy as needed */}
              </div>
              <div className="nav-profile-text">
                <span className="font-weight-bold mb-2">
                  <Trans>{DataName}</Trans>
                </span>
                <span className="font-weight text-small">
                  <Trans>{DataDept}</Trans>
                </span>
                <span className="font-weight text-small">
                  <Trans>{DataPosition}</Trans>
                </span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </a>
          </li>
          <li
            className={
              this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title">
                <Trans>Dashboard</Trans>
              </span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/recruitment") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.basicUiMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => {
                if (
                  DataDept === "HROD" ||
                  Level === "L04" ||
                  Level === "L05" ||
                  Level === "L06" ||
                  Level === "L07" ||
                  Level === "L08" ||
                  Level === "L09"
                ) {
                  this.toggleMenuState("basicUiMenuOpen");
                } else {
                  Swal.fire({
                    title: "Error!",
                    text: "คุณไม่มีสิทธิ์เข้าใช้งาน",
                    icon: "error",
                    confirmButtonText: "Close",
                  });
                }
              }}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Recruitment & Selection</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-account-search menu-icon"></i>
            </div>
            <Collapse in={this.state.basicUiMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/recruitment/requestmanpower")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/recruitment/requestmanpower"
                  >
                    <Trans>Request Manpower</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/recruitment/register")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/recruitment/register"
                  >
                    <Trans>Register Candidate</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/recruitment/pdapprove")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/recruitment/pdapprove"
                  >
                    <span>
                      <Trans>PD Approve</Trans>
                    </span>

                    <span className="badge">
                      <label className="badge badge-info">
                        {this.state.ReqApprove}
                      </label>
                    </span>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/recruitment/candidate")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/recruitment/candidate"
                  >
                    <Trans>Candidate</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/recruitment/manpower")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/recruitment/manpower"
                  >
                    <Trans>Manpower</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/recruitment/tor")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/recruitment/tor"
                  >
                    <Trans>Turn Over Rate</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/recruitment/managementmain") || this.isPathActive("/recruitment/employeedata") || this.isPathActive("/recruitment/newemployee") || this.isPathActive("/recruitment/management") || this.isPathActive("/recruitment/employeeresign")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/recruitment/managementmain"
                  >
                    <Trans>Manpower Management</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive("/form-elements")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <div
              className={
                this.state.formElementsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => {
                Swal.fire({
                  title: "Error!",
                  text: "ยังไม่เปิดใช้งาน",
                  icon: "error",
                  confirmButtonText: "Close",
                });
                //this.toggleMenuState("formElementsMenuOpen");
              }}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Training & Development</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-school-outline menu-icon"></i>
            </div>
            {/*<Collapse in={this.state.formElementsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/form-elements/basic-elements")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/form-elements/basic-elements"
                  >
                    <Trans>Basic Elements</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>*/}
          </li>
          <li
            className={
              this.isPathActive("/tables") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.tablesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => {
                Swal.fire({
                  title: "Error!",
                  text: "ยังไม่เปิดใช้งาน",
                  icon: "error",
                  confirmButtonText: "Close",
                });
                //this.toggleMenuState("tablesMenuOpen");
              }}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Compensation & Benefit</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-table-large menu-icon"></i>
            </div>
            {/*<Collapse in={this.state.tablesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/tables/basic-table")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/tables/basic-table"
                  >
                    <Trans>Basic Table</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>*/}
          </li>
          <li
            className={
              this.isPathActive("/icons") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                this.state.iconsMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => {
                Swal.fire({
                  title: "Error!",
                  text: "ยังไม่เปิดใช้งาน",
                  icon: "error",
                  confirmButtonText: "Close",
                });
                //this.toggleMenuState("iconsMenuOpen");
              }}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Employment & Relation</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-contacts menu-icon"></i>
            </div>
            {/*<Collapse in={this.state.iconsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      this.isPathActive("/icons/mdi")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/icons/mdi"
                  >
                    <Trans>Material</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>*/}
          </li>
          <li
            className={
              this.isPathActive("/user-pages") ? "nav-item active" : "nav-item"
            }
          ></li>

          <li
            className={
              this.isPathActive("/profile") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/profile">
              <span className="menu-title">
                <Trans>Profile</Trans>
              </span>
              <i className="mdi mdi-account-circle menu-icon"></i>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/general-pages")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <div
              className={
                this.state.generalPagesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("generalPagesMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>About</Trans>
              </span>
              <i className="mdi mdi-medical-bag menu-icon"></i>
            </div>
          </li>
          <li
            className={
              this.isPathActive("/general-pages")
                ? "nav-item active"
                : "nav-item"
            }
          >
            <div
              className={
                this.state.generalPagesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => logoutState()}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Logout</Trans>
              </span>
              <i className="mdi mdi-logout menu-icon"></i>
            </div>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
